import {
    Box,
    ButtonGroup,
    Container,
    Divider,
    IconButton,
    Stack,
    Text
    } from '@chakra-ui/react'
    import * as React from 'react'
    import { FaLinkedin, FaTwitter } from 'react-icons/fa'
    import Logo from './logo';
    
    export default function Footer() {
        return (
            <Container as="footer" role="contentinfo" maxW={'8xl'}>
                <Stack
                spacing="8"
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                justify="space-between"
                py={{
                    base: '12',
                    md: '8',
                }}
                >
                <Stack
                    spacing={{
                    base: '6',
                    sm: '1',
                    }}
                    align="start"
                >
                <Box w="150px" h="50px">
                    < Logo />
                </Box>
                    <Text color="muted">Opera eficientemente tu negocio con Nabori.</Text>
                </Stack>
                </Stack>
                <Divider />
                <Stack
                pt="3"
                pb="3"
                justify="space-between"
                direction={{
                    base: 'column-reverse',
                    md: 'row',
                }}
                align="center"
                >
                <Text fontSize="md" color="subtle">
                    &copy; {new Date().getFullYear()} Nabori Labs LLC.  Derechos Reservados
                </Text>
                <ButtonGroup variant="ghost">
                    <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin fontSize="1.25rem" />} />
                    <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
                </ButtonGroup>
                </Stack>
            </Container>
    )}